export function useAnalytics() {
    const nuxtApp = useNuxtApp();
    const logger = useLogs();
    const posthog = nuxtApp.$posthog;

    type Params = Parameters<typeof posthog.capture>;
    type Result = ReturnType<typeof posthog.capture>;

    function logEvent(...args: Params): Result {
        if (!posthog) {
            logger.error('Posthog is unavailable');
        }
        posthog.capture(...args);
    }
    return { logEvent };
}
